<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-input label="成員名稱"
                                   v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="searchdata">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <div class="mb-2">
                    <mdb-btn color="secondary"
                             @click="$router.push('/account/add')">
                        <i class="fa fa-plus mr-2"></i>
                        &nbsp;新增帳號
                    </mdb-btn>
                </div>
                <table class="table table-striped">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>成員名稱</th>
                            <th>帳號</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody v-show="data.adminList.length>0">
                        <tr v-for="(a,ak) in data.adminList"
                            :key="`a_${ak}`">
                            <td scope="row">{{ak+1}}</td>
                            <td>{{a.name}}</td>
                            <td>{{a.account}}</td>
                            <td></td>
                            <td>
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="$router.push('/account/edit/?id='+a.id)">
                                    <i class="fa fa-edit"></i>編輯
                                </mdb-btn>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="data.adminList.length==0">
                        <tr>
                            <td colspan="6">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;getData();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
} from "mdbvue";
import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    page,
  },
  data() {
    return {
      search: {
        name: "",
        page: 1,
      },
      check_search: true,
      data: {
        adminList: [],
        nowPage: 1,
        totalPage: 0,
      },
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      vue.search[i] = vue.query[i] ? vue.query[i] : "";
    }
    console.clear();
    this.getData();
  },
  watch: {
    search: {
      handler() {
        this.check_search = false;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    // 搜尋資料
    searchdata() {
      let vue = this,
        url = vue.$route.path + "?";
      // 判斷是否有更動搜尋條件
      if (!vue.check_search && vue.search.name != "") {
        vue.check_search = true;
        // 調整目前頁碼
        vue.data.nowPage = 1;
        for (let i in vue.search) {
          if (vue.search[i] != "") {
            url += `${i}=${vue.search[i]}&`;
          }
        }
        vue.$router.push(url);
        vue.getData();
      }
    },
    // 取得帳號列表
    getData() {
      let vue = this,
        url = "account/index/?";
      for (let i in vue.search) {
        if (vue.search[i] != "") {
          url += `${i}=${vue.search[i]}&`;
        }
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: url,
          },
        })
        .then((res) => {
          Object.assign(vue.data, res.data);
        });
    },
  },
};
</script>